// Imports
var ___CSS_LOADER_API_IMPORT___ = require("./node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("./node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./static/fonts/segoe-ui-semibold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./static/fonts/segoeuib.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./static/fonts/Comic Sans MS.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./static/fonts/Comic Sans MS Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,300,700,500|Inter:400,700|Patrick+Hand:400|Roboto:400,700|Lora:400,500);"]);
exports.push([module.id, "@import url(https://px.animaapp.com/62303da0ae75681998aa5e14.62303da2ae75681998aa5e17.Pu63N0g.rcp.png);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Segoe UI-SemiBold\";\n  font-style: normal;\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Segoe UI-Bold\";\n  font-style: normal;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Comic Sans MS-Regular\";\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Comic Sans MS-Bold\";\n  font-style: normal;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n\n/* The following line is used to measure usage of this code in production. For more info see our usage billing page */\n\n.screen a {\n  display: contents;\n  text-decoration: none;\n}\n\n.container-center-horizontal {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  pointer-events: none;\n  width: 100%;\n  height: 100%;\n}\n\n.container-center-horizontal > * {\n  pointer-events: auto;\n}\n\n.valign-text-middle {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n* {\n  box-sizing: border-box;\n}\n\n#app,\nbody,\nhtml{\n  height: 100%;\n  font-family: var(--font-family-poppins);\n}\n\n::-webkit-scrollbar {\n  height: 2px;\n  width: 2px;\n}\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 5px grey;\n  border-radius: 10px;\n  border-top: 2px solid transparent;\n  border-bottom: 2px solid transparent;\n}\n::-webkit-scrollbar-thumb {\n  background: var(--fire-bush);\n  border-radius: 10px;\n}\n::-webkit-scrollbar-thumb:hover {\n  background: var(--champagne);\n  cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
