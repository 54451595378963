const firebaseConfig = {
  apiKey: "AIzaSyCIg3Xc3yYNYgXL90XXwaW2cyMafnvusYE",
  authDomain: "africariyoki-4b634.firebaseapp.com",
  databaseURL: "https://africariyoki-4b634-default-rtdb.firebaseio.com",
  projectId: "africariyoki-4b634",
  storageBucket: "africariyoki-4b634.appspot.com",
  messagingSenderId: "171492275085",
  appId: "1:171492275085:web:f2c1364b0feee41e1083c4",
  measurementId: "G-TSPVJ130EK"
};

export default firebaseConfig